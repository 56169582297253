<template>
  <div class="admin-base">
    <v-app-bar app dense color="headerAdmin">
      <v-app-bar-nav-icon
        @click.stop="sidebarMenu = !sidebarMenu"
      ></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-btn icon class="btn-notification" @click="btnNotify()">
        <v-badge
          v-if="countNotify"
          :content="countNotify"
          bordered
          color="error"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>

        <v-icon v-if="!countNotify">mdi-bell</v-icon>
      </v-btn>

      <!-- <div class="theme-toggler" @click="themeTogglerBtn()">
        <v-icon :class="!this.$vuetify.theme.dark ? 'active' : ''"
          >mdi-white-balance-sunny</v-icon
        >
        <v-icon :class="this.$vuetify.theme.dark ? 'active' : ''"
          >mdi-weather-night</v-icon
        >
      </div> -->
    </v-app-bar>
    <v-navigation-drawer
      v-model="sidebarMenu"
      app
      enable-resize-watcher
      width="320"
    >
      <sidebar></sidebar>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <v-row class="fill-height">
          <v-col>
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ConfigStorage from "../../../core/storage/config-storage";
import NotificationServices from "../../../core/services/notification-services";
import Sidebar from "./layout/sidebar.vue";

export default {
  name: "Admin",
  data: () => ({
    sidebarMenu: true,
  }),
  components: {
    Sidebar,
  },
  computed: {
    ...mapState(["countNotify"]),
  },
  async created() {
    const { getDarkMode } = ConfigStorage();
    const mode = await getDarkMode();
    if (mode) {
      this.$vuetify.theme.dark = !mode.darkMode;
      if (!mode.darkMode) {
        document.body.classList.toggle("dark-theme-variables");
        const themeToggler = document.querySelector(".theme-toggler");
        themeToggler.querySelector("i:nth-child(1)").classList.toggle("active");
        themeToggler.querySelector("i:nth-child(2)").classList.toggle("active");
      }
    }

    this.getCountNotifyAlert();
    this.getNotify();

    this.$socket.$subscribe("admin", (resp) => {
      this.getCountNotify({ count: resp.data.count });
    });
  },
  methods: {
    ...mapActions(["getCountNotify", "getNotify"]),
    themeTogglerBtn() {
      const { addDarkMode } = ConfigStorage();
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      addDarkMode(!this.$vuetify.theme.dark);
      document.body.classList.toggle("dark-theme-variables");
      const themeToggler = document.querySelector(".theme-toggler");
      themeToggler.querySelector("i:nth-child(1)").classList.toggle("active");
      themeToggler.querySelector("i:nth-child(2)").classList.toggle("active");
    },
    getCountNotifyAlert() {
      const { notificatioCount } = NotificationServices();
      notificatioCount().then((resp) => {
        const response = resp.data;
        if (response.code === 200) {
          this.getCountNotify({ count: response.data.count });
        }
      });
    },
    btnNotify() {
      this.$router.push("/admin/notification").catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.v-app-bar {
  .btn-notification {
    margin-right: 10px;
  }

  .theme-toggler {
    margin-top: 2px;
    background: var(--color-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.9rem;
    width: 5rem;
    cursor: pointer;
    border-radius: 0.4rem;

    i {
      font-size: 1.3rem;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-dark);
    }

    i.active {
      background: var(--color-primary);
      color: white;
      border-radius: 0.4rem;
    }
  }
}
</style>
