<template>
  <div class="sidebar">
    <div class="sidebar-container">
      <div class="brand">
        <h2>
          <img src="img/home/logo.png" alt="logo" />
          Cosmetics
        </h2>
      </div>

      <v-divider></v-divider>

      <div class="sidebar-avatar">
        <div class="avatar">
          <img :src="user.photo | imageProfile" alt="profile" />
        </div>
        <div class="avatar-info">
          <div class="avatar-text">
            <h3>{{ user.name }} {{ user.lastName }}</h3>
            <small>{{ user.email }}</small>
          </div>
        </div>
      </div>

      <div class="sidebar-menu">
        <ul>
          <li v-for="(item, i) in menu" :key="i" @click="setUrl()">
            <router-link :to="item.path">
              <v-icon>{{ item.icon }}</v-icon>
              <span>{{ item.name }}</span>
              <v-badge
                color="error"
                v-if="item.badge && countNotify"
                inline
                :content="countNotify"
              ></v-badge>
            </router-link>
          </li>
          <li @click="btnCloseSesion()">
            <a>
              <v-icon>mdi-exit-to-app</v-icon>
              <span>Cerrar sesión</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <CustomDialog
      :dialog.sync="dialog"
      :title="'Cerrar sesión'"
      :message="'¿Estás seguro que quieres salir?'"
      @onChange="btnAcceptDialog($event)"
    ></CustomDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useMenu from "./useMenu";
import UserStorage from "../../../../core/storage/user-storage";
import GlobalServise from "../../../../core/services/globals-services";
import CustomDialog from "../../../../shared/components/custom-dialog/CustomDialog.vue";

export default {
  name: "Sidebar",
  data: () => ({
    menu: useMenu,
    user: {},
    dialog: false,
  }),
  components: {
    CustomDialog,
  },
  computed: {
    ...mapState(["countNotify"]),
  },
  async mounted() {
    const { userId } = UserStorage();
    this.user = await userId();
  },
  methods: {
    setUrl() {
      setTimeout(() => {
        const hash = window.location.hash.split("#");
        sessionStorage.setItem("url", hash[1]);
      }, 500);
    },
    btnCloseSesion() {
      this.dialog = true;
    },
    btnAcceptDialog(event) {
      if (event) {
        const { setLogout } = GlobalServise();
        setLogout(true);
      }
    },
  },
};
</script>
