const useMenu = [
  {
    name: "Dashboard",
    path: "/admin/dashboard",
    icon: "mdi-desktop-mac-dashboard",
  },
  // {
  //   name: "Categorías",
  //   path: "/admin/category",
  //   icon: "mdi-tag-multiple",
  // },
  {
    name: "Marcas",
    path: "/admin/mark",
    icon: "mdi-shopping",
  },
  {
    name: "Productos",
    path: "/admin/product",
    icon: "mdi-gift",
  },
  {
    name: "Pedidos",
    path: "/admin/order",
    icon: "mdi-order-bool-descending-variant",
  },
  {
    name: "Reportes",
    path: "/admin/report",
    icon: "mdi-file-chart",
  },
  {
    name: "Clientes",
    path: "/admin/client",
    icon: "mdi-account-plus",
  },
  {
    name: "Vendedores",
    path: "/admin/seller",
    icon: "mdi-account-tie",
  },
  {
    name: "Administradores",
    path: "/admin/user",
    icon: "mdi-account-circle",
  },
  {
    name: "Notificaciones",
    path: "/admin/notification",
    icon: "mdi-bell",
    badge: true,
  },
  {
    name: "Configuración",
    path: "/admin/setting",
    icon: "mdi-cog-outline ",
  },
];

export default useMenu;
